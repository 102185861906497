<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    :isLoadingSaveButton="saveButtonIsLoading"
    @save="onSave('CustomersMain')"
    @cancel="onCancel('CustomersMain')"
    class="site-settings"
  >
    <template #top-panel>
      <div class="site-settings__top-panel top-panel">
        <label class="top-panel__top-select top-select">
          <p class="top-select__description">Клиент</p>
          <sm-select
            v-model="clientId"
            :options="customers.data || []"
            :disabled="sites.isLoading"
            :isLoading="customers.isLoading"
            search
          ></sm-select>
        </label>
        <label class="top-panel__top-select top-select">
          <p class="top-select__description">Название базы</p>
          <sm-select
            v-model="siteName"
            :options="computedSites"
            :disabled="!clientId"
            :isLoading="sites.isLoading"
            search
          ></sm-select>
        </label>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import editableListItemEdit from '@/mixins/editableListItemEdit.js';

import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';

export default {
  name: 'SupportSettings',

  components: {
    SmEditableItem,
    SmSelect,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'Sites',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Настройки сайта',
        },
      ],
      pageHeader: 'Настройки сайта',
      form: {},
      clientId: null,
      siteName: null,
      setupSavingProcess: false,
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers,
      sites: (state) => state.common.sites,
      settings: (state) => state.siteSettings.settings,
    }),

    computedSites() {
      const clientId = this.clientId;
      const sites = this.sites.data || [];

      if (clientId) {
        return sites.map((site) => ({
          ...site,
          value: site.name,
        }));
      }

      return [];
    },

    item() {
      const settings = this.settings.data;
      return settings;
    },

    settingsNotLoaded() {
      const settingsIsLoading = this.settings.isLoading;
      const settingsIsError = this.settings.errors;
      const siteName = this.siteName;

      if (!siteName || settingsIsLoading || settingsIsError) return true;
      return false;
    },

    isEqual() {
      const form = this.form;
      const item = this.item;

      if (!form || !this.lodash.isEqual(form, item)) return false;
      return true;
    },

    saveButtonIsLoading() {
      const settingsIsLoading = this.settings.isLoading;
      const setupSavingProcess = this.setupSavingProcess;

      return settingsIsLoading || setupSavingProcess;
    },

    fields() {
      const settingsNotLoaded = this.settingsNotLoaded;

      return [
        {
          form: [
            {
              type: 'checkbox',
              key: 'denyAdsDesktopLogin',
              label: 'Отклонять авторизацию в десктопной версии АДС клиента',
              disabled: settingsNotLoaded,
            },
          ],
        },
      ];
    },
  },

  watch: {
    item: {
      handler(newVal) {
        if (newVal) {
          this.form = this.lodash.cloneDeep(newVal);
        }
      },
      deep: true,
    },

    clientId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.siteName = null;

        this.getCommonList({
          name: 'Sites',
          params: {
            customerId: newValue,
          },
        });
      }
    },

    siteName(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getSiteSettings(newValue);
      }
    },
  },

  created() {
    this.getCommonList({ name: 'Customers' });
  },

  methods: {
    ...mapActions({
      getSiteSettings: 'siteSettings/getSiteSettings',
      setSiteSettings: 'siteSettings/setSiteSettings',
      getCommonList: 'common/getCommonList',
    }),

    onSave(route) {
      this.$root
        .$confirmModal({
          message: 'Сохранить изменения?',
          leftButtonText: 'Сохранить',
          rightButtonText: 'Отменить',
        })
        .then((response) => {
          if (!response) return;

          this.setupSavingProcess = true;

          this.setSiteSettings({ dbName: this.siteName, settings: this.form })
            .then(() => {
              this.$router.push({ name: route });
            })
            .finally(() => {
              this.setupSavingProcess = false;
            });
        });
    },
  },
};
</script>

<style lang="scss">
.site-settings__top-panel {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.top-panel__top-select {
  width: 100%;
}
</style>
